<template>
  <div class="row">
    <v-col cols="12" sm="12">
      <b-alert
          show
          variant="light"
          class="alert alert-custom alert-success alert-shadow fade show gutter-b mb-0"
      >
        <div class="alert-text mt-2 text-center">
          <h1 class="black--text"><b>Payments</b></h1>
        </div>
      </b-alert>
    </v-col>
    <div class="col-sm-12">
      <h3>All payments such as course payments and other fees will appear here.</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: "payments",
  components: {},
  mounted() {
  },
  methods: {}
};
</script>
